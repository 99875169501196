<template>
	<v-container fluid class="authorization authorization--login pa-0">
		<v-row no-gutters>
			<v-col cols="12" md="6" lg="4">
				<div class="authorization__form form--login d-flex align-center">

					<div class="form__content my-auto">
						<div class="form__content--logo">
							<v-row>
								<v-col cols="12">
									<router-link
										:to="{name: 'Login'}"
									>
										<v-img
											class=""
											max-width="243"
											src="/img/logo_dark.png"
										/>
									</router-link>
								</v-col>
							</v-row>
						</div>
						<div class="form__content--form mt-4 pa-6">
							<v-form
								ref="form"
								lazy-validation
							>
								<v-row>
									<v-col cols="12">
										<h1 class="content__title">
											Forgot Password Form
										</h1>
									</v-col>
									<v-col cols="12">
										<v-text-field
											outlined
											label="Email*"
											prepend-icon="fas fa-at"
											hide-details="auto"
											v-model="email"
											required
											:rules="requiredEmail"
										/>
									</v-col>
									<v-col cols="12" xs="12" sm="12">
										<v-btn
											block
											x-large
											color="primary"
											:loading="loading"
											@click="send"
											id="forgot_password-send_new_password"
										>
											SEND NEW PASSWORD
										</v-btn>
									</v-col>
									<v-col cols="12" xs="12" sm="6">
										<v-btn
											block
											outlined
											color="secondary"
											:to="{name: 'Login'}"
											id="forgot_password-cancel"
										>
											BACK
										</v-btn>
									</v-col>
								</v-row>
							</v-form>
						</div>
					</div>
				</div>
			</v-col>
			<v-col cols="12" md="6" lg="8" class="hidden-sm-and-down">
				<block-carousel/>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import BlockCarousel from "@/views/auth/Block/BlockCarousel";
import {validationRules} from "@/helpers/validationRules";

export default {
	name: 'ForgotPassword',
	components: {BlockCarousel},
	computed: {},
	data: () => ({
		...validationRules(),

		loading: false,
		email: null,
	}),
	methods: {
		send() {
			if (!this.$refs.form.validate()) {
				return;
			}

			this.loading = true;
			this.$store.dispatch('RESTORE_PASSWORD_STORE/restorePasswordEmail', {email: this.email})
				.then((resp) => {
					if (resp.data.success) {
						this.$router.push({name: 'Login'})
					}
					this.loading = false
				})
				.catch(err => {
					console.error(err)
					this.loading = false
				});
		}
	},
	created() {

	},
};
</script>
